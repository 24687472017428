/* src/components/Dashboard.css */

.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    background-color: #ffffff;
    padding-top: 10vh;
}

.dashboard-logo {
    max-width: 150px;
    margin-bottom: 50px;
}

.dashboard-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dashboard-button {
    padding: 15px 30px;
    font-size: 18px;
    color: #ffffff;
    background-color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dashboard-button:hover {
    background-color: #44b839;
}
