
#editCellTextarea {
  height: calc(100vh - 120px); /* Adjust if your Dialog has other elements */
  overflow: auto;
}






.container .groupHeaderButton {
color: #000000; /* Black text */
border: 1px solid #000000; /* Black border */
}

.groupHeaderButton {
color: #000000 !important; /* Black text */
border: 1px solid #000000 !important; /* Black border */
}

.header-class {
font-family: 'Calibri', sans-serif;
}

html, body {
height: 100%;
margin: 0;
padding: 0;
/*margin-top: 0px; */
overflow: hidden; /* Prevent scrolling on the body directly */
}         

.buttonsContainer {
position: sticky;
top: 0;
/* background-color: white; or any background color that matches your design */
padding: 10px 0; /* Add some padding */
display: flex;
justify-content: center; /* Center buttons horizontally */
flex-wrap: wrap; /* Allows multiple rows of buttons if there are many */
z-index: 1000; /* Ensure it's above other content */
border-bottom: 1px solid #ccc; /* Optional: adds a line below the button container */
}

/* Hover and Active states */
.detailViewButton:hover, .groupHeaderButton:hover, .saveChangesButton:hover {
background-color: #cccccc; /* Green background for hover */
}

.detailViewButton:active, .groupHeaderButton:active, .saveChangesButton:active {
background-color: #cccccc; /* Grey background for clicked state */
}

/* Adjusted class for Save Changes button for half size */
.saveChangesButton {
padding: 7.5px 15px; /* Half the original padding */
font-size: 13px; /* Smaller font size if needed */
}
/*
.containerStyle {
display: flex;
flex-wrap: wrap;  Allow items to wrap if not enough space 
justify-content: space-between; /* Spread out the content 
align-items: center; /* Vertically align items 
padding: 10px 0; /* Add some padding
} */
/*
.groupTogglesContainer {
display: flex;
justify-content: center; /* Keeps the buttons centered 
flex-wrap: wrap; /* Allows buttons to wrap in smaller views 
gap: 5px; /* Reduced gap between buttons 
}*/

/* New class for GroupManager header buttons */
.groupHeaderButton {
padding: 7.5px 7.5px; /* Adjust as needed */
font-size: 13px; /* Adjust as needed */
color: #ffffff;
/*background-color: #000000;*/
border: none;
border-radius: 5px;
cursor: pointer;
transition: background-color 0.3s ease;
margin-top: 5px;
margin-right: 10px;
margin-bottom: 5px;
margin-left: 0;
}


.saveChangesButton {
margin-left: auto; /* Push the button to the end of the flex container */
padding: 7.5px 15px;
font-size: 13px; /* Set the same font size for consistency */
/* padding: 7.5px 7.5px; */
margin-top: 5px;
margin-right: 10px;
margin-bottom: 5px;
margin-left: 0;
cursor: pointer;
border-radius: 5px;
border: none;

}


/* Optional: To ensure the last button does not have a right margin */
.groupHeaderButton:not(:last-child) {
margin-right: 10px;
}

.groupHeaderButton.active {
background-color: grey; /* Grey background for active buttons */
}

.dataContainerStyle {
padding-top: 50px;
display: flex;
flex-wrap: wrap;
justify-content: space-around;
align-items: stretch; /* Ensures that children (`s) stretch to fill the container */
}

@media (max-width: 768px) {
.dataContainerStyle {
padding-top: 70px;
display: flex;
flex-wrap: wrap;
justify-content: space-around;
align-items: stretch; /* Ensures that children (`s) stretch to fill the container */
}

}

.uniformTextbox {
resize: none;
width: 100%; /* Ensures it fills its container */
height: 100px;
box-sizing: border-box;
border: 2px solid #ccc;
border-radius: 4px;
padding: 8px; /* Slightly reduced padding for more text space */
margin-bottom: 8px; /* Reduced bottom margin to bring elements closer */
}


.cellStyle {
display: flex;
flex-direction: column;
justify-content: space-between; /* Keeps the header and cell data spaced out */
align-items: center; /* Centers the items horizontally in the container */
width: 48%; /* Adjust width as needed */
margin: 0.25%; /* Minimized margin */
box-sizing: border-box;
}


.cellHeaderStyle {
font-family: 'Calibri', sans-serif;
text-align: center; /* Center-align the text */
font-weight: bold; /* Make the header text bold */
margin-bottom: 5px; /* Add some space between the header and the cell data */
}

.detailViewContent {
width: 100%; /* Fill the container width */
height: 100%; /* Fill the container height */
/* Additional styling as needed */
}


.p-dialog .p-dialog-header  {
margin-bottom: 20px; /* Increase spacing beneath the group of buttons */
/* Other desktop-specific styles here */
}

/* Undo Button Styles */
.undoButton {
/* Initial styling for the Undo button */
padding: 10px 20px;
margin: 10px 0;
font-size: 16px;
background-color: #f44336; /* Red */
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.3s;
}

.undoButton:hover {
background-color: #d32f2f; /* Darker red */
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
.undoButton {
  display: block; /* Ensure the button is block level to apply margin and centering */
  margin: 10px auto; /* Center the button horizontally */
  text-align: center; /* Center the text inside the button */
}

.buttonsContainer {
  display: flex; /* Or 'block' depending on your design */
  justify-content: center; /* Center buttons horizontally */
  padding: 10px 0; /* Maintain vertical padding */
  flex-wrap: wrap; /* Allow buttons to wrap as necessary */
}
.dialogFooter {
  text-align: left; /* Align the container of the button to the left */
  padding-left: 10px; /* Add some padding to not stick to the edge */
}
}

.undoButton {
margin-top: -96px; /* Raise the button by approx. 1 inch */
}

.p-dialog .p-dialog-header .p-dialog-header-close {
margin-left: auto; /* Push close button to the right */
}

/* Desktop Media Query */
@media (min-width: 1024px) {
.cellStyle {
  width: calc(100% / 6 - 1%); /* Adjusted for 6 cells per row on desktop */
  margin: 0.5%; /* Consistent minimized margin for desktop */
}


.detailViewContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /*z-index: 9999; /* Ensure it's above other content */
  overflow: auto; /* Allow scrolling if content exceeds viewport */
  /* Additional styles if needed */
}
.p-dialog .p-dialog-header  {
  justify-content: center; /* Center the buttons horizontally on desktop */
  gap: 1px; /* Corrected typo: Space between buttons */
  padding: 0px; /* Padding around the container */
}
}


.logo-container {
position: absolute;
left: 10px; /* Adjust as needed for desktop view */
}

/* Additional styles for mobile adjustments */
@media (max-width: 768px) {
.detailViewButton, .groupHeaderButton, .saveChangesButton {
  padding: 4px 6px; /* Even smaller padding on mobile */
  margin: 1px; /* Smaller margin to fit more buttons */
  font-size: 11px; /* Smaller font size to save space */
}

.logo-container {
  left: 0; /* Tightly stay to the left side */
}

.p-dialog .p-dialog-header {
  display: flex;
  align-items: center;
}
.p-dialog .p-dialog-header .p-dialog-header-close {
  margin-left: auto; /* Push close button to the right */
}



/* DetailView.css */
.detailViewContainer {
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
z-index: 1050; /* Ensure it is above other content */
overflow: auto; /* Scroll if content exceeds viewport */
}


/* Make sure the content within is also stretching to full height 
.dataContainerStyle, .containerStyle {
height: 100%;*/
/* ... other styles if needed ... 
}*/

/* You might also want to adjust the Dialog to be full height */
.changesSavedDialog .p-dialog {
width: auto !important;
height: auto !important;
max-width: 90vw !important;
max-height: 90vh !important;
}






.changesSavedDialog .p-dialog {
  top: 80vh; /* Slightly lower the dialog from the top */
  bottom: 10vh; /* Also keep a distance from the bottom */
  height: auto; /* Allow dialog height to adjust based on content */
  max-height: 80vh; /* Prevent dialog from taking up the entire screen height */
}



/* Assuming the class for the close button is .p-dialog-header-close or similar */
.p-dialog .p-dialog-header .p-dialog-header-close {
  position: absolute; /* Position the close button absolutely */
  top: 10px; /* Adjust as needed to align vertically */
  right: 10px; /* Adjust as needed to align horizontally */
  background: transparent; /* Remove background color */
  border: none; /* Remove border */
  cursor: pointer; /* Add cursor pointer */
  font-size: 16px; /* Adjust font size */
  padding: 0; /* Remove padding */
}

.p-dialog .p-dialog-content {
  height: calc(100vh - 50px); /* Adjust based on your Dialog header/footer height */
  overflow: auto;
}

.p-dialog {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
}

#editCellTextarea {
  height: calc(100vh - 120px); /* Adjust if your Dialog has other elements */
  overflow: auto;
}

.detailViewContent .closeButton, .detailViewContent .saveButton {
/* Adjusting 'Close' and 'Save' button positions to be more visible */
position: relative; /* Adjust as needed */
bottom: -20px; /* Raise the buttons */
}
.detailViewContent .propertyAddress {
/* Adjusting the position of the propertyAddress */
position: absolute;
top: 20px; /* Lower the position */
left: 10px; /* Adjust as needed for alignment */
}

/* Adjusting button sizes and positions */

}

.header {
display: flex;
justify-content: center; /* Align items horizontally */
align-items: center; /* Align items vertically */
position: fixed;
top: 0;
left: 0;
width: 100%;
z-index: 100;
background-color: #fff;
box-shadow: 0 2px 4px rgba(0,0,0,.1);
padding: 4px 0;
}


.buttonsContainer {
display: flex;
flex-wrap: wrap; /* Allows buttons to wrap */
align-items: center;
gap: 5px; /* Space between buttons */
margin-right: auto; /* Pushes the close button to the end */

}


.header-class {
font-family: 'Calibri', sans-serif;
margin-bottom: 0;
padding-bottom: 0;
}

.groupTogglesContainer {
display: flex;
justify-content: center; /* Center children horizontally */
flex-wrap: wrap; /* Allow buttons to wrap if needed */
gap: 4px; /* Space between buttons */
padding: 4px 0; /* Add some vertical padding */
}


.groupHeaderButton {
padding: 6px 12px; /* Adjust padding to change button size */

margin-top: 0; /* Adjust as needed */
margin-bottom: 0; /* Adjust as needed */
/* Other styles remain unchanged */
}
.p-dialog .p-dialog-header-custom {
display: flex;
justify-content: space-between;
align-items: center;
padding-right: 20px;
}


/* Ensure the close button is visible and on top */
.p-dialog-header-close {
z-index: 1050; /* You might want to increase this if there are elements with a higher z-index */
position: absolute;
top: 10px; /* Adjust based on your header's height */
right: 10px; /* Adjust for proper alignment */
background: transparent;
border: none;
cursor: pointer;
}

/* Centering group buttons and managing spacing */
.groupTogglesContainer {
margin-top: 20px; /* Adds vertical space above the group header buttons */
display: flex;
justify-content: center; /* Keeps buttons centered */
flex-wrap: wrap; /* Allows wrapping if needed */
gap: 10px; /* Manages spacing between buttons */
}


@media (max-width: 768px) {
.p-dialog-header-close {
  top: 2px; /* Adjust to ensure it's not off-screen */
  right: 2px;
  width: 40px; /* Increase size for easier tapping */
  height: 40px;
}
}


.p-dialog-header-close {
position: fixed; /* Or absolute, depending on your specific layout needs */
top: 0px; /* Adjust as needed to ensure visibility at the top */
right: 0px; /* Adjust as needed to stick to the right */
z-index: 1050; /* Higher than other elements to ensure visibility */
padding: 10px; /* Adds some space around the 'X' for easier clicking/tapping */
}

.p-dialog .p-dialog-header .p-dialog-header-close {
position: absolute; /* Position the close button absolutely */
top: 10px; /* Adjust as needed to align vertically */
right: 10px; /* Adjust as needed to align horizontally */
background: transparent; /* Remove background color */
border: none; /* Remove border */
cursor: pointer; /* Add cursor pointer */
font-size: 16px; /* Adjust font size */
padding: 0; /* Remove padding */
}

/* Ensure the close button is visible on all screen sizes */
.p-dialog-header-close {
display: block; /* Ensure the button is always displayed */
position: absolute; /* Position it absolutely within its container */
top: 10px; /* Adjust top position as needed */
right: 10px; /* Adjust right position as needed */
background: transparent; /* Transparent background */
border: none; /* No border */
cursor: pointer; /* Cursor changes to pointer to indicate clickable area */
font-size: 24px; /* Adjust the font size as needed for visibility */
padding: 5px; /* Padding around the 'X' for easier interaction */
z-index: 1050; /* Ensure it's above other content */
}

@media (max-width: 768px) {
.p-dialog-header-close {
top: 5px; /* Slightly adjust for smaller screens */
right: 5px;
font-size: 20px; /* Slightly smaller font size for smaller screens */
}
.p-dialog-header-close {
display: block; /* Ensure the button is always displayed */
position: absolute; /* Position it absolutely within its container */
top: 10px; /* Adjust top position as needed */
right: 10px; /* Adjust right position as needed */
background: transparent; /* Transparent background */
border: none; /* No border */
cursor: pointer; /* Cursor changes to pointer to indicate clickable area */
font-size: 24px; /* Adjust the font size as needed for visibility */
padding: 5px; /* Padding around the 'X' for easier interaction */
z-index: 2147483647; /* Ensure it's above other content */
}
}

.groupHeaderButton {
margin-top: 8px; /* Adjust this value as needed to lower the buttons */
margin: 4px;
/* Other existing styles... */
}

.p-dialog-header-custom {
position: relative;
}

@media (max-width: 768px) {
.p-dialog .p-dialog-header .p-dialog-header-close {
  position: fixed; /* Or absolute, depending on its current placement */
  top: 6px; /* Adjust as needed for optimal visibility */
  right: 20px; /* Adjust to ensure it does not overlap with other elements */
  width: 30px; /* Adjust based on the size of the clickable area */
  height: 30px; /* Adjust based on the size of the clickable area */
}
}



.p-dialog-header-close {
padding: 10px; /* Increases the clickable area for better accessibility */
}

.header {
height: auto; /* Or a specific value that suits your design, avoiding vh that might cause excessive space */
}

.groupHeaderButton {
padding: 2px 4px; /* Reduced padding */
/*margin: 2px; /* Reduced margin */
}


.p-dialog .p-dialog-content {
padding: 20px; /* Adjust as needed to reduce random space */
overflow: auto; /* Ensures content can scroll if it exceeds the dialog's height */
}

.p-dialog {
top: 5vh; /* Adjust based on your needs to ensure it's not cut off */
max-height: 90vh; /* Ensure dialog doesn't exceed viewport height */
overflow-y: auto; /* Allows scrolling within the dialog if content overflows */
}

/* Ensures the dialog background is white */
.p-dialog {
background-color: #fff; /* Adjust as needed */
}

/* Ensures the dialog header background is white */
.p-dialog .p-dialog-header {
background-color: #fff !important; /* Use !important to override PrimeReact styles if necessary */
}

/* Optional: Ensures the close button background matches */
.p-dialog .p-dialog-header .p-dialog-header-close {
background-color: transparent; /* Makes the button's background transparent */
color: #000; /* Adjust the 'x' color if needed */
}
@media (max-width: 768px) {
.p-dialog .p-dialog-content {
  padding-top: 28px; /* Adjust the value based on the desired space */
}
}

.p-dialog {
width: 100vw !important; /* Ensures full viewport width */
max-width: 100% !important; /* Removes any max-width constraints */
height: 100vh !important; /* Ensures full viewport height */
max-height: 100% !important; /* Removes any max-height constraints */
position: fixed !important; /* Positions the dialog fixed within the viewport */
top: 0 !important; /* Aligns the dialog to the top edge of the viewport */
left: 0 !important; /* Aligns the dialog to the left edge of the viewport */
margin: 0 !important; /* Resets any margins to ensure full viewport coverage */
overflow: auto !important; /* Allows scrolling within the dialog if content exceeds the viewport */
}

.p-dialog-content {
height: auto !important; /* Allows the content to adjust based on its size */
overflow-y: auto !important; /* Ensures vertical scrollability if the content overflows */
}

#editCellTextarea {
height: 60vh !important; /* Reduces the textarea size to ensure the save button is visible */
overflow-y: auto !important; /* Ensures vertical scrollability for the textarea */
}

.header {
  display: flex;
  justify-content: center; /* Align items horizontally */
  align-items: center; /* Align items vertically */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100; /* Ensures it's above most content */
  background-color: #fff; /* White background */
  box-shadow: 0 2px 4px rgba(0,0,0,.1); /* Shadow for depth */
  padding: 4px 0;
}

.propertyAddress {
  position: fixed;
  top: 2;
  left: 0;
  width: 100%;
  padding: 0px 10px;
  background-color: #fff; /* Adjust color as needed */
  z-index: 1050; /* Higher than other content */
  font-family: 'Calibri', sans-serif;
  font-size: 16px;
}

.groupHeaderButton {
  margin-top: 15px; /* Increase space below the 'X' close button */
  padding: 6.72px 10.56px; /* Decrease the button size by 4% (previously 7px 12px) */
  font-size: 12.48px; /* Decrease the font size by 4% (previously 13px) */
  margin-right: 2px; /* Further reduce the margin on the sides */
  margin-left: 2px; /* Further reduce the margin on the sides */
  color: #000000; /* Black text */
  border: 1px solid #000000; /* Black border */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttonsContainer {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  flex-wrap: wrap; /* Allows multiple rows of buttons if there are many */
  gap: 7.5px; /* Further reduce the gap between buttons */
  padding: 20px 0; /* Increase vertical padding to create more space from the top */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's above other content */
  border-bottom: 1px solid #ccc; /* Optional: adds a line below the button container */
  margin-right: 22.5%; /* Reduce side margins by 55% */
  margin-left: 22.5%; /* Reduce side margins by 55% */
}

/* Hover and Active states */
.groupHeaderButton:hover {
  background-color: #cccccc; /* Grey background for hover */
}

.groupHeaderButton:active {
  background-color: #aaaaaa; /* Darker grey background for clicked state */
}

/* Optional: To ensure the last button does not have a right margin */
.groupHeaderButton:not(:last-child) {
  margin-right: 2px; /* Further reduce the margin */
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .groupHeaderButton {
    margin-top: 25px; /* Ensure same margin on mobile */
    padding: 6.08px 9.6px; /* Slightly smaller padding on mobile */
    font-size: 11.98px; /* Slightly smaller font size to save space */
    margin: 1.8px; /* Adjust margin to fit more buttons */
  }

  .buttonsContainer {
    flex-wrap: wrap; /* Allow buttons to wrap as necessary */
    justify-content: center; /* Center buttons horizontally */
    padding: 20px 0; /* Ensure same padding on mobile */
    margin-right: 11.25%; /* Reduce side margins by 55% */
    margin-left: 11.25%; /* Reduce side margins by 55% */
  }
}

/* Larger view adjustments */
@media (min-width: 1024px) {
  .saveChangesButton {
    padding: 6.72px 10.56px; /* Same padding as the group buttons */
    font-size: 12.48px; /* Same font size as the group buttons */
  }
}

.uniformTextbox {
  height: 3em; /* Set height to accommodate two lines of text */
  overflow: hidden; /* Hide overflow */
  line-height: 1.5; /* Adjust line-height to fit two lines within the height */
}
.cellStyle {
  margin-bottom: 15px; /* Reduce vertical spacing by 15px */
}

.uniformTextbox {
  height: 3.6em; /* Adjust height to accommodate two lines of text */
  overflow: hidden; /* Hide overflow */
  line-height: 1.8; /* Adjust line-height for better readability */
  width: 100%; /* Ensure it fills its container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border: 2px solid #ccc; /* Border style */
  border-radius: 4px; /* Rounded corners */
  padding: 8px; /* Padding inside the textbox */
  margin-bottom: 8px; /* Space below the textbox */
}

.cellStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space between header and textarea */
  align-items: center; /* Center the items horizontally */
  width: calc(100% / 2 - 1%); /* Two cells per row for mobile view */
  margin: 0.5%; /* Margin around the cells */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

@media (min-width: 768px) {
  .cellStyle {
    width: calc(100% / 6 - 1%); /* Six cells per row for desktop view */
  }
}

.dataContainerStyle {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch; /* Ensures that children stretch to fill the container */
}

.cellHeaderStyle {
  font-family: 'Calibri', sans-serif;
  text-align: center; /* Center-align the text */
  font-weight: bold; /* Make the header text bold */
  margin-bottom: 5px; /* Add some space between the header and the cell data */
}

.uniformTextbox {
  resize: none;
  width: 100%;
  min-height: 2em; /* Minimum height to ensure initial size */
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
  overflow: hidden; /* Hide overflow */
  line-height: 1.5; /* Set the line height for better readability */
}

@media (max-width: 768px) {
  .dataContainerStyle {
      margin-top: 15px; /* Add 15px space for mobile */
  }
}

.dataContainerStyle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch; /* Ensures that children stretch to fill the container */
}
.uniformTextbox {
  margin-bottom: 3px; /* Reduce bottom margin by 5px for mobile */
}
/* Center the dialog header */
.p-dialog .p-dialog-header {
    text-align: center;
    justify-content: center;
}

/* Center the content */
.p-dialog .p-dialog-content {
    text-align: center;
}

/* Style for the list items in the dialog */
.p-dialog .p-dialog-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.p-dialog .p-dialog-content li {
    margin: 10px 0;
}
/* Center the dialog header */
.p-dialog .p-dialog-header {
    text-align: center;
    justify-content: center;
}

/* Center the content */
.p-dialog .p-dialog-content {
    text-align: center;
}

/* Style for the list items in the dialog */
.p-dialog .p-dialog-content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.p-dialog .p-dialog-content li {
    margin: 10px 0;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .dataContainerStyle {
        margin-top: 15px; /* Add 15px space for mobile */
    }

    .uniformTextbox {
        margin-bottom: 3px; /* Reduce bottom margin by 5px for mobile */
    }
}

/* Center the dialog header */
.p-dialog .p-dialog-header {
  text-align: center;
  justify-content: center;
}

/* Center the content */
.p-dialog .p-dialog-content {
  text-align: center;
}

/* Style for the list items in the dialog */
.p-dialog .p-dialog-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.p-dialog .p-dialog-content li {
  margin: 10px 0;
}

.change-box {
  display: inline-block;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin: 0 5px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .dataContainerStyle {
      margin-top: 15px; /* Add 15px space for mobile */
  }

  .uniformTextbox {
      margin-bottom: 3px; /* Reduce bottom margin by 5px for mobile */
  }
}

.change-box {
  display: inline-block;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin: 0 5px;
}

/* Italicize 'null' values */
.change-box:empty::before {
  content: "null";
  font-style: italic;
}

/* Center the dialog header */
.p-dialog .p-dialog-header {
  text-align: center;
  justify-content: center;
}

/* Center the content */
.p-dialog .p-dialog-content {
  text-align: center;
}

/* Style for the list items in the dialog */
.p-dialog .p-dialog-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.p-dialog .p-dialog-content li {
  margin: 10px 0;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .dataContainerStyle {
    margin-top: 15px; /* Add 15px space for mobile */
  }

  .uniformTextbox {
    margin-bottom: 3px; /* Reduce bottom margin by 5px for mobile */
  }
}

