/* AuthPage.css */
.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align items to the start of the container */
    height: 100vh; /* Use the full height of the viewport */
    background-color: #ffffff; /* White background */
    padding-top: 10vh; /* Adjust padding to push content down from the top */
  }
  
  .loginLogo {
    max-width: 150px; /* Adjust based on your logo size */
    margin-bottom: 50px; /* Space between logo and button */
  }
  
  .loginButton {
    padding: 15px 30px; /* Larger button */
    font-size: 18px; /* Larger text */
    color: #ffffff; /* White text */
    background-color: #000000; /* Black background color for button, adjust as needed */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth background color transition on hover */
  }
  
  .loginButton:hover {
    background-color: #44b839; /* Darken button on hover for feedback */
  }
  
  /* AuthPage.css or another relevant CSS file */
.loadingIndicator {
  position: fixed; /* or absolute, depending on your layout */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100; /* Ensure it's above other content */
}
